import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/templates/page.template.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`1. Datenschutz auf einen Blick`}</h2>
    <h3>{`Allgemeine Hinweise`}</h3>
    <p>{`Die folgenden Hinweise geben einen einfachen `}{`Ü`}{`berblick dar`}{`ü`}{`ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers`}{`ö`}{`nlich identifiziert werden k`}{`ö`}{`nnen. Ausf`}{`ü`}{`hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef`}{`ü`}{`hrten Datenschutzerkl`}{`ä`}{`rung.`}</p>
    <h3>{`Datenerfassung auf dieser Website`}</h3>
    <h4>{`Wer ist verantwortlich f`}{`ü`}{`r die Datenerfassung auf dieser Website?`}</h4>
    <p>{`Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k`}{`ö`}{`nnen Sie dem Abschnitt `}{`„`}{`Hinweis zur Verantwortlichen Stelle`}{`“`}{` in dieser Datenschutzerkl`}{`ä`}{`rung entnehmen.`}</p>
    <h4>{`Wie erfassen wir Ihre Daten?`}</h4>
    <p>{`Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.`}{` `}{`B. um Daten handeln, die Sie in ein Kontaktformular eingeben.`}</p>
    <p>{`Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.`}{` `}{`B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.`}</p>
    <h4>{`Wof`}{`ü`}{`r nutzen wir Ihre Daten?`}</h4>
    <p>{`Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew`}{`ä`}{`hrleisten. Andere Daten k`}{`ö`}{`nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.`}</p>
    <h4>{`Welche Rechte haben Sie bez`}{`ü`}{`glich Ihrer Daten?`}</h4>
    <p>{`Sie haben jederzeit das Recht, unentgeltlich Auskunft `}{`ü`}{`ber Herkunft, Empf`}{`ä`}{`nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au`}{`ß`}{`erdem ein Recht, die Berichtigung oder L`}{`ö`}{`schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k`}{`ö`}{`nnen Sie diese Einwilligung jederzeit f`}{`ü`}{`r die Zukunft widerrufen. Au`}{`ß`}{`erdem haben Sie das Recht, unter bestimmten Umst`}{`ä`}{`nden die Einschr`}{`ä`}{`nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust`}{`ä`}{`ndigen Aufsichtsbeh`}{`ö`}{`rde zu.`}</p>
    <p>{`Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k`}{`ö`}{`nnen Sie sich jederzeit an uns wenden.`}</p>
    <h3>{`Analyse-Tools und Tools von Dritt`}{`­`}{`anbietern`}</h3>
    <p>{`Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.`}</p>
    <p>{`Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl`}{`ä`}{`rung.`}</p>
    <h2>{`2. Hosting und Content Delivery Networks (CDN)`}</h2>
    <h3>{`Externes Hosting`}</h3>
    <p>{`Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die `}{`ü`}{`ber eine Website generiert werden, handeln.`}</p>
    <p>{`Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf`}{`ü`}{`llung gegen`}{`ü`}{`ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).`}</p>
    <p>{`Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erf`}{`ü`}{`llung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.`}</p>
    <p>{`Wir setzen folgenden Hoster ein:`}</p>
    <p>{`Netlify, Inc.`}<br />{`
2325 3rd Street, Suite 296`}<br />{`
San Francisco CA 94107`}<br /></p>
    <h4>{`Abschluss eines Vertrages `}{`ü`}{`ber Auftragsverarbeitung`}</h4>
    <p>{`Um die datenschutzkonforme Verarbeitung zu gew`}{`ä`}{`hrleisten, haben wir einen Vertrag `}{`ü`}{`ber Auftragsverarbeitung mit unserem Hoster geschlossen.`}</p>
    <h2>{`3. Allgemeine Hinweise und Pflicht`}{`­`}{`informationen`}</h2>
    <h3>{`Datenschutz`}</h3>
    <p>{`Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers`}{`ö`}{`nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl`}{`ä`}{`rung.`}</p>
    <p>{`Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers`}{`ö`}{`nlich identifiziert werden k`}{`ö`}{`nnen. Die vorliegende Datenschutzerkl`}{`ä`}{`rung erl`}{`ä`}{`utert, welche Daten wir erheben und wof`}{`ü`}{`r wir sie nutzen. Sie erl`}{`ä`}{`utert auch, wie und zu welchem Zweck das geschieht.`}</p>
    <p>{`Wir weisen darauf hin, dass die Daten`}{`ü`}{`bertragung im Internet (z.`}{` `}{`B. bei der Kommunikation per E-Mail) Sicherheitsl`}{`ü`}{`cken aufweisen kann. Ein l`}{`ü`}{`ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m`}{`ö`}{`glich.`}</p>
    <h3>{`Hinweis zur verantwortlichen Stelle`}</h3>
    <p>{`Die verantwortliche Stelle f`}{`ü`}{`r die Datenverarbeitung auf dieser Website ist:`}</p>
    <p>{`Julia Katheder`}<br />{`
Jerg-Rathgeb-Stra`}{`ß`}{`e 6`}<br />{`
71034 B`}{`ö`}{`blingen`}<br /></p>
    <p>{`Telefon: +49 176 72 64 1819`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:info@bloomista.garden"
      }}>{`info@bloomista.garden`}</a></p>
    <p>{`Verantwortliche Stelle ist die nat`}{`ü`}{`rliche oder juristische Person, die allein oder gemeinsam mit anderen `}{`ü`}{`ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.`}{` `}{`B. Namen, E-Mail-Adressen o. `}{`Ä`}{`.) entscheidet.`}</p>
    <h3>{`Speicherdauer`}</h3>
    <p>{`Soweit innerhalb dieser Datenschutzerkl`}{`ä`}{`rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f`}{`ü`}{`r die Datenverarbeitung entf`}{`ä`}{`llt. Wenn Sie ein berechtigtes L`}{`ö`}{`schersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel`}{`ö`}{`scht, sofern wir keinen anderen rechtlich zul`}{`ä`}{`ssigen`}{` `}{` Gr`}{`ü`}{`nde f`}{`ü`}{`r die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L`}{`ö`}{`schung nach Fortfall dieser Gr`}{`ü`}{`nde.`}</p>
    <h3>{`Hinweis zur Datenweitergabe in die USA`}</h3>
    <p>{`Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, k`}{`ö`}{`nnen Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh`}{`ö`}{`rden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen k`}{`ö`}{`nnten. Es kann daher nicht ausgeschlossen werden, dass US-Beh`}{`ö`}{`rden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu `}{`Ü`}{`berwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungst`}{`ä`}{`tigkeiten keinen Einfluss.`}</p>
    <h3>{`Widerruf Ihrer Einwilligung zur Datenverarbeitung`}</h3>
    <p>{`Viele Datenverarbeitungsvorg`}{`ä`}{`nge sind nur mit Ihrer ausdr`}{`ü`}{`cklichen Einwilligung m`}{`ö`}{`glich. Sie k`}{`ö`}{`nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm`}{`ä`}{`ß`}{`igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber`}{`ü`}{`hrt.`}</p>
    <h3>{`Widerspruchsrecht gegen die Datenerhebung in besonderen F`}{`ä`}{`llen sowie gegen Direktwerbung (Art. 21 DSGVO)`}</h3>
    <p>{`WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR`}{`Ü`}{`NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F`}{`Ü`}{`R EIN AUF DIESE BESTIMMUNGEN GEST`}{`Ü`}{`TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKL`}{`Ä`}{`RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K`}{`Ö`}{`NNEN ZWINGENDE SCHUTZW`}{`Ü`}{`RDIGE GR`}{`Ü`}{`NDE F`}{`Ü`}{`R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN `}{`Ü`}{`BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS`}{`Ü`}{`BUNG ODER VERTEIDIGUNG VON RECHTSANSPR`}{`Ü`}{`CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).`}</p>
    <p>{`WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F`}{`Ü`}{`R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).`}</p>
    <h3>{`Beschwerde`}{`­`}{`recht bei der zust`}{`ä`}{`ndigen Aufsichts`}{`­`}{`beh`}{`ö`}{`rde`}</h3>
    <p>{`Im Falle von Verst`}{`ö`}{`ß`}{`en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh`}{`ö`}{`rde, insbesondere in dem Mitgliedstaat ihres gew`}{`ö`}{`hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma`}{`ß`}{`lichen Versto`}{`ß`}{`es zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.`}</p>
    <h3>{`Recht auf Daten`}{`­`}{`ü`}{`bertrag`}{`­`}{`barkeit`}</h3>
    <p>{`Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf`}{`ü`}{`llung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem g`}{`ä`}{`ngigen, maschinenlesbaren Format aush`}{`ä`}{`ndigen zu lassen. Sofern Sie die direkte `}{`Ü`}{`bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.`}</p>
    <h3>{`SSL- bzw. TLS-Verschl`}{`ü`}{`sselung`}</h3>
    <p>{`Diese Seite nutzt aus Sicherheitsgr`}{`ü`}{`nden und zum Schutz der `}{`Ü`}{`bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl`}{`ü`}{`sselung. Eine verschl`}{`ü`}{`sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von `}{`„`}{`http://`}{`“`}{` auf `}{`„`}{`https://`}{`“`}{` wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.`}</p>
    <p>{`Wenn die SSL- bzw. TLS-Verschl`}{`ü`}{`sselung aktiviert ist, k`}{`ö`}{`nnen die Daten, die Sie an uns `}{`ü`}{`bermitteln, nicht von Dritten mitgelesen werden.`}</p>
    <h3>{`Auskunft, L`}{`ö`}{`schung und Berichtigung`}</h3>
    <p>{`Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft `}{`ü`}{`ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf`}{`ä`}{`nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L`}{`ö`}{`schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k`}{`ö`}{`nnen Sie sich jederzeit an uns wenden.`}</p>
    <h3>{`Recht auf Einschr`}{`ä`}{`nkung der Verarbeitung`}</h3>
    <p>{`Sie haben das Recht, die Einschr`}{`ä`}{`nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k`}{`ö`}{`nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr`}{`ä`}{`nkung der Verarbeitung besteht in folgenden F`}{`ä`}{`llen:`}</p>
    <ul>
      <li parentName="ul">{`Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben`}{`ö`}{`tigen wir in der Regel Zeit, um dies zu `}{`ü`}{`berpr`}{`ü`}{`fen. F`}{`ü`}{`r die Dauer der Pr`}{`ü`}{`fung haben Sie das Recht, die Einschr`}{`ä`}{`nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.`}</li>
      <li parentName="ul">{`Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm`}{`ä`}{`ß`}{`ig geschah/geschieht, k`}{`ö`}{`nnen Sie statt der L`}{`ö`}{`schung die Einschr`}{`ä`}{`nkung der Datenverarbeitung verlangen.`}</li>
      <li parentName="ul">{`Wenn wir Ihre personenbezogenen Daten nicht mehr ben`}{`ö`}{`tigen, Sie sie jedoch zur Aus`}{`ü`}{`bung, Verteidigung oder Geltendmachung von Rechtsanspr`}{`ü`}{`chen ben`}{`ö`}{`tigen, haben Sie das Recht, statt der L`}{`ö`}{`schung die Einschr`}{`ä`}{`nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.`}</li>
      <li parentName="ul">{`Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw`}{`ä`}{`gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen `}{`ü`}{`berwiegen, haben Sie das Recht, die Einschr`}{`ä`}{`nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.`}</li>
    </ul>
    <p>{`Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr`}{`ä`}{`nkt haben, d`}{`ü`}{`rfen diese Daten `}{`–`}{` von ihrer Speicherung abgesehen `}{`–`}{` nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus`}{`ü`}{`bung oder Verteidigung von Rechtsanspr`}{`ü`}{`chen oder zum Schutz der Rechte einer anderen nat`}{`ü`}{`rlichen oder juristischen Person oder aus Gr`}{`ü`}{`nden eines wichtigen `}{`ö`}{`ffentlichen Interesses der Europ`}{`ä`}{`ischen Union oder eines Mitgliedstaats verarbeitet werden.`}</p>
    <h3>{`Widerspruch gegen Werbe-E-Mails`}</h3>
    <p>{`Der Nutzung von im Rahmen der Impressumspflicht ver`}{`ö`}{`ffentlichten Kontaktdaten zur `}{`Ü`}{`bersendung von nicht ausdr`}{`ü`}{`cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr`}{`ü`}{`cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.`}</p>
    <h2>{`4. Datenerfassung auf dieser Website`}</h2>
    <h3>{`Server-Log-Dateien`}</h3>
    <p>{`Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns `}{`ü`}{`bermittelt. Dies sind:`}</p>
    <ul>
      <li parentName="ul">{`Browsertyp und Browserversion`}</li>
      <li parentName="ul">{`verwendetes Betriebssystem`}</li>
      <li parentName="ul">{`Referrer URL`}</li>
      <li parentName="ul">{`Hostname des zugreifenden Rechners`}</li>
      <li parentName="ul">{`Uhrzeit der Serveranfrage`}</li>
      <li parentName="ul">{`IP-Adresse`}</li>
    </ul>
    <p>{`Eine Zusammenf`}{`ü`}{`hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.`}</p>
    <p>{`Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website `}{`–`}{` hierzu m`}{`ü`}{`ssen die Server-Log-Files erfasst werden.`}</p>
    <h3>{`Anfrage per E-Mail, Telefon oder Telefax`}</h3>
    <p>{`Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.`}</p>
    <p>{`Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf`}{`ü`}{`llung eines Vertrags zusammenh`}{`ä`}{`ngt oder zur Durchf`}{`ü`}{`hrung vorvertraglicher Ma`}{`ß`}{`nahmen erforderlich ist. In allen `}{`ü`}{`brigen F`}{`ä`}{`llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.`}</p>
    <p>{`Die von Ihnen an uns per Kontaktanfragen `}{`ü`}{`bersandten Daten verbleiben bei uns, bis Sie uns zur L`}{`ö`}{`schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f`}{`ü`}{`r die Datenspeicherung entf`}{`ä`}{`llt (z.`}{` `}{`B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen `}{`–`}{` insbesondere gesetzliche Aufbewahrungsfristen `}{`–`}{` bleiben unber`}{`ü`}{`hrt.`}</p>
    <h2>{`5. Newsletter`}</h2>
    <h3>{`Newsletter`}{`­`}{`daten`}</h3>
    <p>{`Wenn Sie den auf der Website angebotenen Newsletter beziehen m`}{`ö`}{`chten, ben`}{`ö`}{`tigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die `}{`Ü`}{`berpr`}{`ü`}{`fung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschlie`}{`ß`}{`lich f`}{`ü`}{`r den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.`}</p>
    <p>{`Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschlie`}{`ß`}{`lich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters k`}{`ö`}{`nnen Sie jederzeit widerrufen, etwa `}{`ü`}{`ber den `}{`„`}{`Austragen`}{`“`}{`-Link im Newsletter. Die Rechtm`}{`ä`}{`ß`}{`igkeit der bereits erfolgten Datenverarbeitungsvorg`}{`ä`}{`nge bleibt vom Widerruf unber`}{`ü`}{`hrt.`}</p>
    <p>{`Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gel`}{`ö`}{`scht. Wir behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu l`}{`ö`}{`schen oder zu sperren.`}</p>
    <p>{`Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k`}{`ü`}{`nftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur f`}{`ü`}{`r diesen Zweck verwendet und nicht mit anderen Daten zusammengef`}{`ü`}{`hrt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.
`}<strong parentName="p">{`Sie k`}{`ö`}{`nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse `}{`ü`}{`berwiegen.`}</strong></p>
    <h3>{`MailChimp mit deaktivierter Erfolgsmessung`}</h3>
    <p>{`Diese Website nutzt die Dienste von MailChimp f`}{`ü`}{`r den Versand von Newslettern. Anbieter ist die Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.`}</p>
    <p>{`MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert werden kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z. B. E-Mail-Adresse), werden diese auf den Servern von MailChimp in den USA gespeichert. Wir haben die Erfolgsmessung bei Mailchimp deaktiviert, sodass Mailchimp ihr Verhalten beim `}{`Ö`}{`ffnen unserer Newsletter nicht auswerten wird.`}</p>
    <p>{`Wenn Sie nicht m`}{`ö`}{`chten, dass Ihre Daten an Mailchimp `}{`ü`}{`bertragen werden, m`}{`ü`}{`ssen Sie den Newsletter abbestellen. Hierf`}{`ü`}{`r stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verf`}{`ü`}{`gung. Des Weiteren k`}{`ö`}{`nnen Sie den Newsletter auch direkt auf der Website abbestellen.`}</p>
    <p>{`Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie k`}{`ö`}{`nnen diese Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtm`}{`ä`}{`ß`}{`igkeit der bereits erfolgten Datenverarbeitungsvorg`}{`ä`}{`nge bleibt vom Widerruf unber`}{`ü`}{`hrt.`}</p>
    <p>{`Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters aus der Newsletterverteilerliste gel`}{`ö`}{`scht. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unber`}{`ü`}{`hrt.`}</p>
    <p>{`Die Daten`}{`ü`}{`bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest`}{`ü`}{`tzt. Details finden Sie hier:
`}<a parentName="p" {...{
        "href": "https://mailchimp.com/legal/data-processing-addendum/#9._Jurisdiction-Specific_Terms"
      }}>{`https://mailchimp.com/legal/data-processing-addendum/#9._Jurisdiction-Specific_Terms`}</a>{`.`}</p>
    <p>{`Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k`}{`ü`}{`nftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur f`}{`ü`}{`r diesen Zweck verwendet und nicht mit anderen Daten zusammengef`}{`ü`}{`hrt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.
`}<strong parentName="p">{`Sie k`}{`ö`}{`nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse `}{`ü`}{`berwiegen.`}</strong></p>
    <p>{`N`}{`ä`}{`heres entnehmen Sie den Datenschutzbestimmungen von MailChimp unter:
`}<a parentName="p" {...{
        "href": "https://mailchimp.com/legal/terms/"
      }}>{`https://mailchimp.com/legal/terms/`}</a>{`.`}</p>
    <h4>{`Abschluss eines Data-Processing-Agreements`}</h4>
    <p>{`Wir haben ein sog. `}{`„`}{`Data-Processing-Agreement`}{`“`}{` mit MailChimp abgeschlossen, in dem wir MailChimp verpflichten, die Daten unserer Kunden zu sch`}{`ü`}{`tzen und sie nicht an Dritte weiterzugeben.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      